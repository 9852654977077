import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Homepage from "./pages/HomePage/HomePage";
import PageRequest from "./pages/PageRequest/PageRequest";
import UserList from "./screens/UserList/UserList";
import GrantAccess from "./screens/GrantAccess/GrantAccess";


const Index = () => <PageRequest/>
const About = () => <GrantAccess/>
const Users = () => <UserList/>
const Request = () => <PageRequest/>
const HomePage = () => <Homepage></Homepage>

const AppRouter = (props) => (
  <Router>
    <div>
      <Route path="/" exact component={Index} />
      <Route path="/homepage"  component={HomePage} />
      <Route path="/grant/" component={About} />
      <Route path="/users/" component={Users} />
      <Route path="/request/" component={Request} />
      <Route path="/form/:formid" component={Request} />     
    </div>
  </Router>
);

export default AppRouter;