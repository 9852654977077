import React, { Component } from 'react';
import SideBar from './screens/SideBar/SideBar';
import NavbarMain from './screens/NavbarMain/NavbarMain';
import AppRouter from './AppRouter';
import LoginPage from './pages/LoginPage/LoginPage';
import * as Drupal from './extenalAPI/Drupal'
import {connect} from 'react-redux'
import { inputAccount, inputTokenUser, formValue, inputUserCredential } from './actioncreator';
import GrantAccess from './screens/GrantAccess/GrantAccess';
import {authContext} from './services/adal'

class App extends Component {

  constructor(props){
      super(props)
      this.state ={
        loginStatus: false,
      }
  }
  componentDidMount(props){
    try{
      let userCredential = authContext._user
      this.props.inputUserCredential(userCredential)
      this.setState({
        loginStatus : true
      })
    }
    catch(error){
        this.setState({
          loginStatus : false
        })
    }
  }
  render(){
    if(this.state.loginStatus === false){
      return (
        <LoginPage/>
      )
    }
    else{
      return (
        <div className="App">
              <div className='container-fluid p-0'>
                <div className='row'>
                    <div className='col-md-12'>
                      <NavbarMain></NavbarMain>
                    </div>
                </div>
                <div className='row w-100'>
                    <div className='col-md-2 content-body'>
                        <SideBar></SideBar>
                    </div>
                    <div className='col-md-6 content-primary'>
                        <AppRouter></AppRouter>
                    </div>
                </div>
              </div>
          </div>
      )
    }
  }
}

const stateToProps = state =>{
  return state
}

// Action creator pass as a props on class APP
export default connect(stateToProps,{inputUserCredential})(App);
