import React from 'react';

export const adal_tenant = process.env.REACT_APP_ADAL_TENANT
export default class NavbarMain extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      softwareName : 'Target Process'
    }
  }
  // signOut = () =>{
    // localStorage.clear()
  // }
  render() {
    return (
      <div>
          <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap  shadow">
            <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="/">{this.state.softwareName}</a>
            <ul className="navbar-nav px-3">
              <li className="nav-item text-nowrap">
                <a className="nav-link" /* onClick={this.signOut} href="/"*/ href={'https://login.microsoftonline.com/'+adal_tenant+'/oauth2/logout'}>Sign out</a>
              </li>
            </ul>
          </nav>
      </div>
    );
  }
}