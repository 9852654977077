import Axios from 'axios'
const HOST = process.env.REACT_APP_API_HOST

/**
 * User Management Request
 */
export const Auth = (params=[]) =>{
    let form = new FormData()
    let keys = Object.keys(params)
    keys.map((v,i)=>
        form.append(v,params[v])
    )
    
    let request = Axios({
        method: 'post',
        url: HOST+'/oauth/token',
        data: form
    });
    return Promise.resolve(request)
}

export const RefeshToken = (refreshToken)=>{
    let params = new FormData();
    params.append("grant_type","refresh_token")
    params.append("refresh_token",refreshToken)
    params.append("client_id",process.env.REACT_APP_CLIENT_ID)
    params.append("client_secret",process.env.REACT_APP_CLIENT_SECRET)
    let request = Axios({
        headers : {
            "Authorization" : "Application/json"
        },
        method: 'post',
        url: HOST+'/oauth/token',
        data : params
    });
    return Promise.resolve(request)
}

export const upgradeToken =(currentUser,accessToken)=>{
    

    let params = new FormData();
    params.append("token",currentUser)
    let request = Axios({
        headers : {
            "Authorization" : "Bearer "+ accessToken 
        },
        method: 'post',
        url: HOST+'/api/updateToken',
        data : params
    });
    return Promise.resolve(request)
}



export const CurrentUser=(token)=>{
    let request = Axios({
        headers : {
            "Authorization" : "Bearer "+ token 
        },
        method: 'get',
        url: HOST+'/api/currentUser',
    });
    return Promise.resolve(request)
}



/**
 * Form Management Request 
 */
export const ListForm =()=>{
    let request = Axios({
        method: 'get',
        url: HOST+'/api/form',
    });
    return Promise.resolve(request)
}


export const GetForm=(type)=>{
   
    let request = Axios({
        method: 'get',
        url: HOST+'/webform_rest/'+type+'/fields?_format=json',
    });
    return Promise.resolve(request)
}


/**
 * Project Management Request
 */
export const GetProject= (email)=>{

    let request = Axios({
        method: 'get',
        url : HOST+'/api/targetprocess/project?email='+email
    });
    return Promise.resolve(request)
}

export const SubmitRequest = (params) =>{
    let request = Axios({
        method: 'POST',
        url: HOST+'/api/targetprocess/request',
        data: params
    });
    return Promise.resolve(request)
}

/**
 *USer Story
 */
export const UserStory= (access_token)=>{

    let request = Axios({
        method: 'GET',
        url: "https://tp.voxteneo.com/api/v1/Projects?format=json&access_token="+access_token,
    });
    
    return Promise.resolve(request)
}


export const SendAttachment = async (formdata,mimetype) =>{
    let upload = await Axios({
        method : 'post',
        url    : HOST+'/api/targetprocess/uploadAttachment',
        headers: {
            "Content-Type":"multipart/form-data",
            "X-Content-Type-Options": "nosniff",
        },
        mimetype: mimetype,
        data  : formdata,
    })
    return Promise.resolve(upload)
}