import React from 'react'
import Dynamic from '../../components/Dynamic/Dynamic';
import {
    Button,
} from 'reactstrap'
import * as Drupal from '../../extenalAPI/Drupal'
import {connect} from 'react-redux'
import { FormLoading, MiniLoading } from '../../helper/Loading';
import reactElementToJSXString from 'react-element-to-jsx-string'
import {unregister} from '../../serviceWorker';
import {runWithAdal} from 'react-adal'
import {authContext, authSubmit, adalApiFetch} from '../../services/adal'

class RequestFrom extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            loading    : true,
            data       : [],
            errorMsg   : '',
            errorStatus: true,
            prefix     : "field_",
            isSubmited : false,
            button :{
                label : "Submit",
                disabled : false,
            }
        }
    }

    componentDidMount(props){
        this.setState({
            errorMsg   : "Please select form type",
            errorStatus: true,
            loading    : false
        })
    }

    componentWillReceiveProps(props,state){
        if(props.getFormType !== null){
           this.getForm(props)
        }
    }

    getForm = async (props)=>{
        this.setState({
            loading : true
        })
        let [form,projectList] = [
            await Drupal.GetForm(props.getFormType),
            await Drupal.GetProject(props.getUserCredential.userName)
        ];
        
        let formData = await this.includeIntoForm(
            props.getFormType,
            form,
            projectList
        )
        this.setState({
            loading : false,
            errorStatus : false,
            data : formData.data,
            errorMsg :''
        })
    }
    includeIntoForm = (type,form,projectList) =>{
       
        let selectProjectlist = []
        projectList.data.Items.map((v)=>{
            return selectProjectlist[v.Project.Id] = v.Project.Name
        })

        
       
        let completeForm = form.data

        form.data = []
        form.data.project = {
            "#options" : selectProjectlist,
            "#type" : "select",
            "#admin_title": "Project",
            "#title": "Project",
            "#webform_key" : "project",
            "#required" : true
        }

        form.data.severity = {
            "#options" : {
                urgent : "urgent", 
                high : "high", 
                medium : "medium", 
                low : "low", 
            },
            "#type" : "select",
            "#admin_title": "severity",
            "#title": "Severity",
            "#webform_key" : "severity",
            "#required" : true
        }
        
        form.data.title = {
         
            "#type" : "textfield",
            "#admin_title": "Title",
            "#title": "Title",
            "#webform_key" : "title",
            "#required" : true
        }
        Object.keys(localStorage).map((v,i)=>{
            if(v.includes('field_')){
                localStorage.removeItem(v);
            }
        })
        Object.keys(completeForm).map((v,i)=>{
            if(form.data[v] === undefined){
                form.data[v] = completeForm[v]
            }
            return true
        })
        return form
    }
    isStillUpload = ()=>{
        let status = localStorage.getItem("still_upload")
        if(status === "true"){
            return true;
        }
        return false;
    }

    checkUpload = () => {
        if(localStorage.getItem("still_upload") === null){
            return false
        }
        if(this.isStillUpload()===false){
            this.setState({
                isSubmited : false,
                loading    : false,
                errorStatus: false,
                label : "submit",
                button : {
                    disabled : false,
                    label : "Submit"
                },
                errorMsg : ""
            })
        }
        if(this.isStillUpload()===true){
            this.setState({
                isSubmited : false,
                loading    : false,
                errorStatus: false,
                label : "submit",
                button : {
                    disabled : true,
                    label : <MiniLoading></MiniLoading>
                },
                errorMsg : "File Still Uploading ..."
            })
        }
    }

    submitRequest = async () =>{
        Object.keys(this.state.data).map((v,i)=>{
            let tmp_key = this.state.data[v]["#webform_key"];
            if(localStorage.getItem("field_"+tmp_key) == null ){
                if(document.getElementById('field_'+tmp_key) !== null){
                    let id_obj = "field_"+tmp_key;
                    if(typeof(document.getElementById(id_obj)) !== 'undefined'){
                        if(document.getElementById(id_obj).value == ""){
                            localStorage.setItem("field_"+tmp_key, "")    
                        }else{
                            localStorage.setItem("field_"+tmp_key, document.getElementById(id_obj).value);
                        }
                    }
                }
            }
        })
        if(this.isStillUpload() === true){
    
            this.setState({
                isSubmited : false,
                loading    : false,
                errorStatus: false,
                label : "submit",
                button : {
                    disabled : true ,
                    label : "Submit"
                },
                errorMsg : "File Still Uploading ..."
            })
            return false;
        }

        else{
            this.setState({
                button :{
                    label : <MiniLoading/>,
                    disabled : true,
                }
            })
            let keys = Object.keys(localStorage)
            let form  = new FormData();
            let fromValue = [];
            localStorage.setItem('adal.token.keys', localStorage.getItem('adal.token.keys_tmp'))
            keys.map((v,i)=>{
                if(v.startsWith(this.state.prefix)){
                    let field = v.substr(this.state.prefix.length,v.length)
                    let value = localStorage.getItem(v)
                    fromValue[field]= value
                    form.append(field,value)
                }
                return true
            })
            localStorage.setItem('adal.token.keys', '')
            
            let description = this.createDescription();
            fromValue['description']= description
            form.append("description",description)
            form.append("email",this.props.getUserCredential.userName)
            form.append("form_name", this.props.getFormType)
            let validation = this.doValidation(fromValue)
            if(!validation){
                return false
            }
            else{
                var stats_login = true;
                adalApiFetch().then(response => {
                    console.log(response);
                }).catch(e => {
                    if(e.msg == "login_required"){
                        stats_login = false;
                    }
                    if(stats_login === true){
                        Drupal.SubmitRequest(form)
                        .then((response)=>{
                                if(response.status === 200){
                                    this.setState({
                                        isSubmited : true,
                                        loading    : false,
                                        errorStatus: false,
                                        button :{
                                            label : "Submit",
                                            disabled : false,
                                        }
                                    })
                                    Object.keys(this.state.data).map((v,i)=>{
                                        let tmp_key = this.state.data[v]["#webform_key"]
                                        localStorage.removeItem('field_'+tmp_key);
                                    })
                                }else{
                                    this.setState({
                                        isSubmited : false,
                                        loading    : false,
                                        errorStatus: false,
                                        label : "submit",
                                        button : {
                                            disabled : false ,
                                            label : "Submit"
                                        },
                                        errorMsg : "Error input !"
                                    })
                                }
                        },(e)=>{
                                this.setState({
                                    isSubmited : false,
                                    loading    : false,
                                    errorStatus: false,
                                    label : "submit",
                                    button : {
                                        disabled : false ,
                                        label : "Submit"
                                    },
                                    errorMsg : "Error input!"
                                })
                        })
                    }else{
                        runWithAdal(authContext,()=>{ })
                    }
                });
            }
        }
    }

    doValidation = (fromValue)=>{
        let form = this.state.data
        let notification = []
        Object.keys(form).map((v,i)=>{
            if(fromValue[v] === null && form[v]['#required'] === true){
               notification.push('Field '+ v.replace("_"," ") + ' is required')
            }   
            if(fromValue[v] === undefined && form[v]['#required'] === true){
                notification.push('Field '+ v.replace("_"," ") + ' is required')
            }
            if(fromValue[v] == "" && form[v]['#required'] === true){
                notification.push('Field '+ v.replace("_"," ") + ' is required')
            }
            return true
        })

        if(notification.length <1){
            return true
        }else{
            localStorage.setItem('adal.token.keys', localStorage.getItem('adal.token.keys_tmp'))
            runWithAdal(authContext,()=>{ 
                localStorage.setItem('adal.token.keys', '')
            })

            this.setState({
                isSubmited : false,
                loading    : false,
                errorStatus: false,
                label : "submit",
                button : {
                    disabled : false ,
                    label : "Submit"
                },
                errorMsg : notification
            })
            return false
        }

    }
    reloadRequest(){
        window.location.href = window.location.origin
    }

    createDescription = () =>{
        let keys = Object.keys(this.state.data)
        let arrDesc=[]
        let exist  = ['project','title','severity']
        for(let i in keys){
            if(exist.indexOf(keys[i])< 0 ){
                if(this.state.prefix+keys[i] !== 'field_procedure_url') {
                    arrDesc.push(this.createHtml(this.state.prefix+keys[i]))
                }
            }
        }
        return arrDesc.join('<hr></hr>')
    }

    createHtml   = (keys) =>{
        
        let value = localStorage.getItem(keys)
        if(value !== null && value.startsWith('<img')){
            return value;
        }
        else{
            return reactElementToJSXString(
                <div>
                    <b>{keys.substr(this.state.prefix.length,keys.length).replace(/_/g, ' ').trimEnd()}</b>
                    <p>
                        {value.trimStart().trimEnd()}
                    </p>
                </div>
            )
        }
    }

    

    render(){
        
        if(this.state.loading === true ){
            return (
                <div className="text-center">
                   <FormLoading></FormLoading>
                </div>
            )
        }
        
        else if(this.state.errorStatus === true){
            return (
                <div className="text-center">{this.state.errorMsg}</div>
            )
        }
        else{

            if(this.state.isSubmited===true){
                return (
                    <div className="text-center">
                            Your request has been send, create new request ? 
                            <button className="btn btn-success" onClick={this.reloadRequest}> Yes create the other one</button>
                    </div>
                )
            }else{
                return (
                    <div>
                        <ErrorMsg data={this.state.errorMsg}></ErrorMsg>
                        <Dynamic 
                            id           = {this.state.data} 
                        /> 
                        <Button onMouseEnter={this.checkUpload} onClick={this.submitRequest} 
                            color="primary" 
                            className="btn-block btn-lg"
                            disabled = {this.state.button.disabled}
                            >
                            {this.state.button.label}
                        </Button>
                    </div>
                )
            } 
        }
    }
}


export function ErrorMsg(props){
    if(typeof props.data === "object"){
        return(
            <div className="text-left text-danger">
                <ul>
                    {props.data.map((v,i)=>
                        <li key={i}>
                            {v}
                        </li>
                    )}
                </ul>
            </div>
        )
    }
    else{
        return(
            <div className="text-center">
                {props.data}
            </div>
        )
    }
    
}
const stateToProps = state =>{
    return state
}
export default connect(stateToProps)(RequestFrom)
