import React from 'react'
import {
    Card,CardBody,
    Button,Alert
} from 'reactstrap'
import {connect} from 'react-redux'
import * as Drupal from  '../../extenalAPI/Drupal'
import { MiniLoading } from '../../helper/Loading';
import { inputAccount } from '../../actioncreator';


class  GrantAccess extends React.Component{
   
   constructor(props){
       super(props)
       this.state ={
            alertClass : '',
            errorMsg :'',
            current_user :'',
            button :{
                props :false , color : "primary", label : "Submit"
            },
            token:'',
            refeshToken:'',
            step :[]

       }
   }
   
   
  
   componentDidMount(){
       this.setState({
        step : [
            'Login To your account',
            'Go to profile',
            'Below your profile picture, click access token'
        ]
       })
   }

   componentWillReceiveProps(props,state){
 
       if(props.getTokenUser!==null){
            this.setState({
                current_user : props.getTokenUser,
                token : props.getAccount.access_token,
                refeshToken: props.getAccount.refresh_token
            })
       } else if (props.getTokenUser === '') {
           this.setState({
               token : props.getAccount.access_token,
               refeshToken: props.getAccount.refresh_token
           })
       }
        if(this.state.token === null){           
            window.location.reload()
        }
   }

   changeKey = (e)=>{
        this.setState({
            current_user : e
        })
   }
   submitKey=()=>{
        this.setState({
            button : {
                props :true , color : "primary", label : <MiniLoading></MiniLoading>
            }
        })    
       Drupal.upgradeToken(this.state.current_user,  this.props.getAccount.access_token)
       .then((response)=>{
            if(response.status === 200){
            
                this.setState({
                    button : {
                        props :false , color : "primary", label : "Submit"
                    },
                    alertClass : "alert alert-success",
                    errorMsg : "Success Update Data, You need to re-Login . . ."
                })

                setTimeout(function () {
                    localStorage.removeItem("last_user")
                    window.location.href = window.location.origin + '/request'
                },2000)
                
            }
       }).catch((e)=>{
            
            this.setState({
                button : {
                    props :false , color : "primary", label : "Submit"
                },
                alertClass : "alert alert-danger",
                errorMsg : "Error change data, Reload automatic"
            })

            setTimeout(function () {
                window.location.href = window.location.origin + '/grant'
            },2000)
       })
   }
   

    render(){
       
        return (
            <Card>
                <CardBody>
                    <h3> Grant Access</h3>
                    <Alert color={this.state.alertClass}>
                        {this.state.errorMsg}
                    </Alert>
                    <p>
                        You need to add your grant token from <a href="https://tp.voxteneo.com/"> Target Proccess</a>
                    </p>

                    {this.state.step.map((v,i)=>
                            <p key={i}> {i+1}. {v}</p>
                    )}
                    <div className='row-field'>
                        <input 
                        onChange    = {(e)=>this.changeKey(e.target.value)} 
                        value       = {this.state.current_user} 
                        placeholder = 'Input Token' 
                        className   = 'form-control form-control-lg'>
                            
                        </input>
                    </div>
                    
                    <Button 
                        disabled={this.state.button.props} 
                        color={this.state.button.color}
                        className='btn-lg'
                        onClick ={this.submitKey}
                        >
                        {this.state.button.label}
                    </Button>
                </CardBody>
            </Card>
        )
    }
}


const stateToProp = state =>{
    
    return state
}
export default connect(stateToProp,{inputAccount})(GrantAccess)