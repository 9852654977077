
export const inputAccount = (params) =>{
    return {
        type : 'INPUT_ACCOUNT',
        payload : params
    }
}


export const inputTokenUser = (params) =>{
    return {
        type : 'INPUT_TOKEN_USER',
        payload : params
    }
}


export const inputFormType = (params) =>{
    return {
        type : 'INPUT_FORM_TYPE',
        payload : params
    }
}


export const uploadStatus = (params) =>{
    return {
        type : 'UPLOAD_STATUS',
        payload : params
    }
}

export const formValue = (params)=>{
    return {
        type : 'FORM_VALUE',
        payload: params
    }
}

export const inputUserCredential = (params)=>{
    return {
        type : 'USER_CREDENTIAL',
        payload: params
    }
}