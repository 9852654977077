import {combineReducers}  from  'redux'

const getAccount = (lastValue =null, action) =>{
    if(action.type==='INPUT_ACCOUNT'){
        return action.payload
     }
     return lastValue
} 

const getTokenUser = (lastValue = null, action) =>{
    if(action.type==='INPUT_TOKEN_USER'){
        return action.payload
     }
     return lastValue
} 

const getFormType  = (lastValue =null,action)=>{
    
    if(action.type==='INPUT_FORM_TYPE'){
        return action.payload
     }
     return lastValue
}

const getUploadStatus  = (lastValue =null,action)=>{
    
    if(action.type==='UPLOAD_STATUS'){
        return action.payload
     }
     return lastValue
}
const getFormValue = (lastValue=[],action)=>{
    if(action.type==='FORM_VALUE'){
        return action.payload
     }
     return lastValue
}
const getUserCredential  = (lastValue=[],action)=>{
    if(action.type==='USER_CREDENTIAL'){
        return action.payload
     }
     return lastValue
}
const reducer = combineReducers({ 
    getAccount,
    getTokenUser,
    getFormType,
    getUploadStatus,
    getFormValue,
    getUserCredential
 })

export default reducer;