/**
 * All form Should Be have a prefix called "field_"
 */
import React, {useState}  from 'react'
import * as fileUpload from '../../services/fileUpload'
import {  Uploading } from '../../helper/Loading'
import { connect } from 'react-redux'
import { uploadStatus } from '../../actioncreator'

function Dynamic (props){
    let  fieldsApi = props.id
    let  fields    = []
    
    if(typeof fieldsApi !== 'undefined'){
        Object.keys(fieldsApi).map((key,i)=>{
            if(fieldsApi[key]['#webform_key'] !== 'procedure_url') {
                let component = createComponent(fieldsApi[key]['#type'],fieldsApi[key],i)
                return fields.push(component)
            }
        })
        
        return (
            <div>{fields}</div>
        )
    }else{
        return (
            <div> Loading ... </div>
        )
    }
    
}
const stateToProps =  state =>{
   
    return state;
}

export default connect(stateToProps,{uploadStatus})(Dynamic)

function createComponent(jsonKey,jsonValue,i){
    switch (jsonKey) {
        case 'textfield':
            return (
                <TextField jsonKey={jsonKey} jsonValue={jsonValue} key={i}/>
            )
            
        case 'textarea' :
            return (
                <TextArea jsonKey={jsonKey} jsonValue={jsonValue} key={i}/>
            )
            
        case 'checkbox' :
            return (
                <CheckBox jsonKey={jsonKey} jsonValue={jsonValue} key={i}/>
            )
            
        case 'select' :
           return(
               <SelectBox jsonKey={jsonKey} jsonValue={jsonValue} key={i}/>
           )
        case 'webform_image_file' :
            return <FileInput jsonKey={jsonKey} jsonValue={jsonValue} key={i} />

        default:
            return <TextField key={jsonKey} value={jsonValue} iteration={i} />
           
            
    }
}

function FileInput(props){

    let defalutText = "Upload FIle"
    let isRequired  = props.jsonValue['#required'];
    let fieldName   = 'field_'+props.jsonValue['#webform_key']
    const [fileName, setFileName]     = useState(defalutText)
    const [textAlert,setTextAlert]    = useState("")
    const [classAlert, setClassAlert] = useState("")
    const [loadingClass, setLoading] = useState("label-form-upload")
    const [link, setLink] = useState('')
 

    const handleFilesChange =(e)=>{
        if(e.target.files[0] !== undefined){
            setFileName(<Uploading></Uploading>)
            setLoading("label-form-upload-loading")
            localStorage.setItem('still_upload',true) 
            fileUpload.fileUpload(e,fieldName).then((response)=>{
                localStorage.setItem('still_upload',false) 
                setLoading("label-form-upload")
                if(response.status === 200){
                    let uri= response.data.items[0].uri
                    setFileName(uri)
                    setLink(uri)
                }
            })
        }
        else{
            setFileName(defalutText)
        }

        if(isRequired === true && e.target.files[0].name === undefined){
            setTextAlert(props.jsonValue['#title']+' is required')
            setClassAlert("text-center")
        }
        else{
            setTextAlert('')
            setClassAlert('')
        }
    }
    return (
        <div key={props.iteration}>
            <div className='row row-field'>
                <div className='col-md-3'>
                    <label> {props.jsonValue['#title']}</label></div>
                <div className='col-md-9'>
                    <div className="text-center">
                        <label className={loadingClass} htmlFor="validatedCustomFile">
                            {fileName}
                        </label>
                        <input 
                            multiple  
                            onChange={handleFilesChange} 
                            type="file" 
                            className="form-control-lg" 
                            id={"field_"+props.jsonValue["#webform_key"]}
                            style={{display:'none'}}
                        />
                        <span className={classAlert}>
                            {textAlert}
                        </span>
                    </div>
                    <a target="__blank" href={link}> {link}</a>
                </div>
            </div>
        </div>
        
    )
}


function  TextField  (props){

   const [textAlert , setTextAlert] = useState('')
   const [classAlert, setClassAlert] = useState('')
   let isRequired = props.jsonValue["#required"];
   let iconRequired = (isRequired === true) ? '*':''

   const savingValue=(e) => {
        
        localStorage.setItem(
            'field_'+props.jsonValue['#webform_key'],
            e.target.value
        )
   }

   const checkingValue = (e)=>{
        if(e.target.value.length < 1 && isRequired === true){
            setTextAlert(props.jsonValue['#title']+' is required')
            setClassAlert("text-danger")
        }
        else{
            setTextAlert('')
            setClassAlert('')
        }
   }
   return (
       <div>
            <div className='row row-field'>
                <div className='col-md-3'>
                    <label> {props.jsonValue["#title"]} {iconRequired} </label>
                </div>
                <div className='col-md-9'>
                    <div className="">
                        <input  
                            type="text" 
                            className="form-control-lg form-control" 
                            id={"field_"+props.jsonValue["#webform_key"]}
                            required= {isRequired}
                            onKeyUp={savingValue}
                            onChange = {checkingValue}
                        />
                     <span className={classAlert}>
                         {textAlert}
                     </span>
                    </div>
                </div>
            </div>
       </div>
   )
}

function  TextArea  (props){
   
    
   const [textAlert , setTextAlert] = useState('')
   const [classAlert, setClassAlert] = useState('')
   let isRequired = props.jsonValue["#required"];
   let iconRequired = (isRequired === true) ? '*':''
 
    const savingValue=(e) => {
        localStorage.setItem(
            'field_'+props.jsonValue['#webform_key'],
            e.target.value
        )
   }
   const checkingValue = (e)=>{
        if(e.target.value.length < 1 && isRequired === true){
            setTextAlert(props.jsonValue['#title']+' is required')
            setClassAlert("text-danger")
        }
        else{
            setTextAlert('')
            setClassAlert('')
        }
    }
    return (
        <div>
             <div className='row row-field'>
                 <div className='col-md-3'>
                     <label> {props.jsonValue["#title"]} {iconRequired} </label>
                 </div>
                 <div className='col-md-9'>
                     <div className="">
                         <textarea  
                             type="text" 
                             className="form-control-lg form-control"  
                             id={"field_"+props.jsonValue["#webform_key"]}
                             required= {isRequired}
                             onKeyUp={savingValue}
                             onChange={checkingValue}
                         />
                        <span className={classAlert}>
                         {textAlert}
                        </span>
                     </div>
                 </div>
             </div>
        </div>
    )
 }

function  CheckBox  (props){
    const [required] = useState(false)
    const [value,setValue] = useState(false)
    let iconRequired = (props.jsonValue["#required"] === true) ? '*':''
 
    const savingValue=(e) => {
         setValue(e.target.value)
         localStorage.setItem('field_'+props.jsonValue['#webform_key'],value)
    }
    return (
        <div>
             <div className='row row-field'>
                 <div className='col-md-3'>
                     <label> {props.jsonValue["#title"]} {iconRequired} </label>
                 </div>
                 <div className='col-md-9'>
                     <div className="">
                         <input  
                             type="checkbox" 
                             className="form-control-lg form-control"  
                             id={"field_"+props.jsonValue["#webform_key"]}
                             required= {required}
                             onKeyUp={savingValue}
                         />
                      
                     </div>
                 </div>
             </div>
        </div>
    )
 }


function SelectBox(props){
    
    const [classAlert,setClassAlert] = useState('')
    const [textAlert,setTextAlert] = useState('')
    

    let required  = props.jsonValue['#required']
    let iconRequired = (required === true) ? '*' : ''
    const handlerChangeSelect = (e) =>{
        if(e.target.value.length < 1 && required === true){
            setClassAlert("text-danger")
            setTextAlert(props.jsonValue['#title']+'  is required')
            localStorage.setItem('field_'+props.jsonValue['#webform_key'],"")
        }
        else{
            setTextAlert("")
            localStorage.setItem('field_'+props.jsonValue['#webform_key'],e.target.value)
        }
    }
    
    return(
        <div key={props.iteration}>
            <div className='row row-field'>
                <div className='col-md-3'>
                    <label>  {props.jsonValue["#title"]} {iconRequired}</label>
                </div>
                <div className='col-md-9'>

                    
                <select id={"field_"+props.jsonValue["#webform_key"]} className='form-control-lg form-control' onChange={handlerChangeSelect}>
                    <option value =''>
                        -Select-
                    </option>
                    {Object.keys(props.jsonValue["#options"]).map((v,i)=>
                        <option value={v} key={i}>{props.jsonValue["#options"][v]}</option>
                    )}
                    
                </select>
                    <span className={classAlert}>{textAlert}</span>
                </div>
            </div>
        </div>
    )
    
}
