import Axios from 'axios'

const HOST_TP = process.env.REACT_APP_API_TP


export const uploadFile = async (currentUser,formdata,mimeTye)=>{

    let jsonpAdapter = require('axios-jsonp')
    let upload = await Axios({
        method : 'post',
        url    : HOST_TP+'/UploadFile.ashx?access_token='+currentUser,
        headers: {
            "Content-Type":"multipart/form-data",
            "X-Content-Type-Options": "nosniff"
        },
        mimeTye : mimeTye,
        data  : formdata,
        adapter :  jsonpAdapter,
        callbackParamName: 'c'
    })
    return Promise.resolve(upload)
}


export const GetUserList = async (currentUser)=>{

    let upload = await Axios({
        method : 'post',
        url    : HOST_TP+'/api/v1/users?access_token='+currentUser+'&format=json&take=1000',
        headers: {
            "Content-Type":"application/json"
        },
    })
    return Promise.resolve(upload)
}