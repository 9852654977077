import {AuthenticationContext, adalFetch} from 'react-adal'

export const endPoint = process.env.REACT_APP_ADAL_END_POINT

const appId = process.env.REACT_APP_ADAL_APP_ID

export const config = {
    cacheLocation: 'localStorage',
    clientId: appId,
    endpoints: {
        api: endPoint
    },
    postLogoutRedirectUri: window.location.origin,
    redirectUri : window.location.origin + '/request',
    tenant: process.env.REACT_APP_ADAL_TENANT
}

export const authContext =  new AuthenticationContext(config)

export const adalApiFetch = (fetch, url, options) => adalFetch(authContext, config.endpoints.api, fetch, url, options);