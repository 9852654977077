import React from 'react'
import reactElementToJSXString from 'react-element-to-jsx-string'
import { SendAttachment } from '../extenalAPI/Drupal';

export function fileUpload(e,fileName){

    let storageName        = []
    let formdata       = new FormData();
    let mimeType 

    mimeType = e.target.files[0].type;
    formdata.append('file',e.target.files[0])
   
    try{
        let upload  = SendAttachment(formdata,mimeType)

        upload.then((response)=>{
            if(response.data.items && response.data.items.length > 0 ){
        
                response.data.items.map((v,i) => {
                  return  storageName.push(htmlCretate(v))
                })
                localStorage.setItem(fileName,storageName.join())
           }
        })
        return Promise.resolve(upload)
        
    }catch(e){
        console.log(e)
    }
    
}

function htmlCretate(v){
   if(v.mimeType!=null){
        if(v.mimeType.startsWith("image")){
            return reactElementToJSXString(<img alt={v.name} src={v.uri}></img>)
        }

        else if(v.mimeType.startsWith("video")){
            return reactElementToJSXString(
                    <video controls>
                        <source src={v.uri} type={v.mimeType}/>
                    </video>
            )
        }else{
            return reactElementToJSXString(<a href={v.uri}>{v.name}</a>)
        }
    }
    else{
        return reactElementToJSXString(<a href={v.uri}>{v.name}</a>)
    }
}
