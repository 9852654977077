import React from 'react'

export default class  ButtonSSO extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            isLogin     : false, 
            token : false
        }
    }

    loginAzure =()=>{
        let tokenKey = localStorage.getItem("adal.token.keys")
        let access_token = localStorage.getItem("adal.access.token.key"+tokenKey.substr(0,tokenKey.length-1))
        this.setState({
            isLogin : true,
            token : access_token
        })
        console.log(access_token)
    }
    render(){
        return (
            <>
                <a 
                    href="#"
                    onClick = {this.loginAzure}
                    className="btn btn-primary btn-lg btn-block">
                    Login SSO
                </a>
            </>
        )
    }
} 