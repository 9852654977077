import React from 'react'
import { 
    Card, CardBody, CardTitle,
    Label, 
    Button,
    Input
} from 'reactstrap'
import * as Drupal from '../../extenalAPI/Drupal'
import {connect} from 'react-redux'
import {inputAccount, inputTokenUser} from '../../actioncreator/index'
import Logo from '../../assets/img/voxteneo-logo.png'
import ButtonSSO from '../../components/ButtonSSO/ButtonSSO'

class LoginForm extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            username  : '',
            password : '',
            loadingMsg : "Submit",
            loading : false
        }
    }
    auth = (e) => {
        e.preventDefault()
        let login = Drupal.Auth({
            username     : this.state.username,
            password     : this.state.password,
            grant_type   : process.env.REACT_APP_GRANT_TYPE,
            client_id    : process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,  
        })
        login.then((response)=>{
            if(response.status!==200){
               this.setState({
                    errorMsg : 'Server error, please try again #'+response.status,
                    errorClass : 'alert text-danger text-center',
                    loading  : false,
                    loadingMsg : "Submit"
               })
            }

            else {
               let getAccount = response.data
               getAccount.tokenUser = null
               this.props.inputAccount(response.data)
               window.localStorage.setItem("last_user", window.btoa(response.data.refresh_token))
            }
        }).catch((error) => {
            this.setState({
                errorMsg : 'Your username and/or password do not match',
                errorClass : 'alert text-danger text-center',
                loading  : false,
                loadingMsg : "Submit"
            })
        })
    }
    render(){
      
        return (
           <div className='login-form'>
                <Card outline color='secondary'>
                <form onSubmit={this.auth}>  
                    <CardBody>
                        <img  alt='Vox Teneo' src={Logo}></img>
                    </CardBody>
                    <CardBody>
                        <CardTitle className="mb-4"><h3>Login</h3></CardTitle>
                        <div 
                            className={this.state.errorClass}>
                            {this.state.errorMsg}
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-xs-6'>
                                <Label>Username</Label>
                            </div>
                            <div className='col-md-9 col-xs-6'>
                                <Input autoComplete="username" placeholder='Username' onChange={(e)=>this.setState({username:e.target.value})} />
                            </div>
                        </div>
                        <br></br>
                        <div className='row'>
                            <div className='col-md-3 col-xs-6'>
                                <Label>Password</Label>
                            </div>
                            <div className='col-md-9 col-xs-6'>
                                <Input autoComplete="current-password"  placeholder='Password' type='password' onChange={(e)=>this.setState({password:e.target.value})} />
                            </div>
                        </div>         
                    </CardBody>
                    <CardBody> 
                        <Button 
                            type      = 'submit' 
                            disabled  = {this.state.loading} 
                            className = 'btn-block btn-lg' 
                            color     = 'primary' 
                            onClick = {this.auth}
                            >
                            {this.state.loadingMsg}
                        </Button>
                        <ButtonSSO/>
                    </CardBody>
                </form>
            </Card>
           </div>
        )
    }
}

const mapStateToProps = state =>{
    return state
}

export default connect(mapStateToProps,{inputAccount,inputTokenUser})(LoginForm)