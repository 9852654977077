import React from 'react'
import {adalApiFetch } from 'react-adal'
import * as Drupal from './extenalAPI/Drupal'

export default class FirstPage extends React.Component{



    constructor(props){
        super(props)
        this.state={
            isLogin : false,
            token : false,
        }
    }

    componentDidMount(props){
      

        let tokenKey = localStorage.getItem("adal.token.keys")
        let access_token = localStorage.getItem("adal.access.token.key"+tokenKey.substr(0,tokenKey.length-1))
        this.setState({
            isLogin : true,
            token : access_token
        })
        Drupal.UserStory(access_token)
    }


    componentWillReceiveProps(props){
        
        console.log(props)
    }


    render(){
        return <div>{this.state.token}</div>
    }

}