import React from 'react'
import Image from 'react-shimmer'
export default class DashboardCards extends React.Component{
    
    constructor(props){
        super(props)
      
        this.state = {
            imageSource : 'https://coda.newjobs.com/api/imagesproxy/ms/cms/content30/images/stop-complaining-at-work.jpg',
            cardCount  : props.count,
            classCount : 'col-md-'+props.count,
            arrCount : [1,2,3],
        }

        // this.countCards(props.count)
    }
    
    countCards(count){
        
        let arr=[]
        if(typeof count!=='undefined'){
            for(let i = 0 ; i <  this.state.cardCount.length; i++){
                arr.push(i)
            }
        }else{
            arr.push(1)
        }

        this.setState({
            arrCount : arr
        })
       
    }
    
    render(){
        
        return (
          <div className='row'>
            {this.state.arrCount.map((i,n)=>
                <div key={i} className={this.state.classCount}>
                <div className="card bg-dark text-dar">
                    
                    <Image
                          src={this.state.imageSource}
                          width={150} height={150}
                          style={{objectFit: 'cover'}} // Style your <img> 
                          delay={25}
                          duration={0.9}
                    />
                    <div className="card-img-overlay">
                        <h5 className="card-title">Card Title</h5>
                       
                    </div>
                    </div>
                </div>
            )}

          </div>
           
        )
    }
}