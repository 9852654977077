import React from  'react'
import LoginForm from '../../screens/LoginForm/LoginForm';
export default class LoginPage extends React.Component{
    render(){
        return(
            <div className='container-fluid login-page'>
            <div className='row'>
                <div className='col-lg-4' ></div>
                <div className='col-lg-4' >
                  <LoginForm/>
                </div>
                <div className="col-lg-4"></div>
            </div>
          </div>
        )
    }
}