import React from 'react'
import {Spinner} from 'reactstrap'

export const Loading = () =>{
    return(
        <div className='loading-screen'>
            <h3> <Spinner color="primary" /> Loading ... </h3>
        </div>
    )
}

export const FormLoading = ()=>{
    return (
        <div className="text-center">
            <h3> <Spinner color="primary" /> Creating Form ... </h3>
        </div>
    )
}

export const MiniLoading = () =>{
    return(
       <div>
           <Spinner color="default" /> Loading ...
       </div>
    )
}


export const Uploading = () =>{
    return(
       <div>
           <Spinner color="default" /> Uploading
       </div>
    )
}