import React from 'react'
import {
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
    Input,
    Button, Spinner,
} from 'reactstrap'
import {connect} from 'react-redux'
import * as TP from '../../extenalAPI/TargetProcess'

class UserList extends React.Component{
    constructor(props){
        super(props)
        
        this.state ={
            userList : [],
            backup : [],
            errorNetwork : <h3><Spinner color='primary'/> Create List..</h3>,
        }
      
      
    }

    componentWillReceiveProps(props,state){
        if(props.getTokenUser!==null){
            this.getUserList(props.getTokenUser)
        }
    }
    async getUserList(token){
      try{
        let request =   TP.GetUserList(token)
        request.then((response)=>{
              if(typeof response.data !=='undefined'){
                  this.setState({
                      userList : response.data.Items
                  })
               
              }
        })
      }catch(e){
            this.setState({
                errorNetwork: 'Ups, Cant Connect To the Target Process...!'
            })
      }
     
  
        
    } 

    createRequest = (e) =>{
        window.location.href = e.target.id
    }
    searchUsername = (e) => {
        let data = this.state.userList
        let key = e.target.value.toLowerCase()
        let newList = []

       
        if(this.state.backup.length < 1){
            this.setState({
                backup : this.state.userList
            })
        }
       
        if(data.length > 0 ){
            
            
            for(let i in data){
               if(data[i].FirstName.toLowerCase().startsWith(key)===true){
                   newList.push(data[i]) 
               }
            }

            if(newList.length<1 || key.length < 1){
                this.setState({
                    userList:  this.state.backup,
                })
            }else{
                this.setState({
                    userList:  newList
                })
            }
            
        }
    }
    render(){
        if(this.state.userList.length < 1)
        {
            return (
                <div> {this.state.errorNetwork} </div>
            )
        }else{
            return(
                <div>
                    <h3> Search User</h3>
                     <Input 
                         className='form-control form-control-lg'
                         onChange={this.searchUsername}
                         placeholder ="input name"
                     />
                     <br></br>
                     <ListGroup>
                         {this.state.userList.map((val,i)=>
                             <ListGroupItem key={i} >
                                 <ListGroupItemHeading>{val.FirstName}</ListGroupItemHeading>
                                 <blockquote>
                                         <ListGroupItemText>{val.Email}</ListGroupItemText>
                                         
                                 </blockquote>
                                 <div className='pull-right'>
                                 <Button id='/request' onClick={this.createRequest} color='primary'> Create Request</Button>
                                 </div>
                             </ListGroupItem>
                         )}
                     </ListGroup>
                </div>
             )
        }        
       
    }
}


const stateToPros = (state)=>{
    return state
}
export default connect(stateToPros)(UserList)