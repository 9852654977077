import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {unregister} from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import reducer from './reducer/index'
import './sass/app.scss'
import {runWithAdal} from 'react-adal'
import {authContext} from './services/adal'
import FirstPage from './FirstPage';

const DO_NOT_LOGIN = false
runWithAdal(authContext,()=>{ 
    unregister()
    localStorage.setItem('adal.token.keys_tmp', localStorage.getItem('adal.token.keys'))
    localStorage.setItem('adal.token.keys', '')
    ReactDOM.render(
        <Provider store={createStore(reducer)}>
            <App/>
        </Provider>, 
        document.getElementById('root')
    );
},DO_NOT_LOGIN)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

