import React from 'react'
// import LoginForm from '../../screens/LoginForm/LoginForm';
import NavbarMain from '../../screens/NavbarMain/NavbarMain';
import DashboardCards from '../../components/DashboardCards/DashboardCards';




export default class Homepage extends React.Component{
   render(){
       return (
          <div>
               <div className='container-fluid'>
                <NavbarMain></NavbarMain>
           </div>
           <div className='container'>
                
                <div className='row'>
                    <div className='col-md-8'>
                            <h1> Homepage</h1>
                    </div>
                    <div className='col-md-4'>
                       {/* <LoginForm></LoginForm> */}
                    </div>
                </div>
                <div className='row'>
                    <DashboardCards count='4'></DashboardCards>
                </div>
           </div>
          </div>
       )
   }
}