import React from 'react'
import RequestFrom from '../../screens/RequestForm/RequestFrom';
import {
    Card,CardBody
} from 'reactstrap'
import { inputAccount,inputFormType } from '../../actioncreator';
import {connect} from 'react-redux'
import { Loading } from '../../helper/Loading';
import * as Drupal from '../../extenalAPI/Drupal'

class PageRequest extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            isLogin : false,
            listForm : [],
            prefix : null,
            formType : false,
        }
    }

  
    componentDidMount(){
        if(this.props.getUserCredential !== undefined){
            this.setState({
                isLogin : true
            })
            this.listForm()
        }
    }
  

    listForm () {
        Drupal.ListForm().then((response)=>{
            if(response.status===200){
                this.setState({
                    listForm : response.data
                })
             }
        })
    }

    loadRequest =(value)=>{
      this.props.inputFormType(value)
    }
   
    render(){
        if(this.state.isLogin === false){
            return (
                <div className="container">
                    <Loading></Loading>
                </div>
            )
        }else{
            return(
                <div className="container-fluid">
                   <Card>
                       <CardBody>
                          <h3> Request Form</h3>
                            <select className="form-control" onChange={(e)=>this.loadRequest(e.target.value)}>
                                <option value="">-Choose Request -</option>
                                {this.state.listForm.map((v,i)=>
                                    <option key={i} value={v.id}> {v.title}</option> 
                                )}
                            </select>
                       </CardBody>
                       <CardBody>
                           <RequestFrom/>
                       </CardBody>
                   </Card>
                </div>
            )
        }
        
    }
}

const stateToProps = state =>{
    return state
}
export default connect(stateToProps,{inputFormType})(PageRequest)